* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

html body {
  background-image: url("../src/images/subjectsbackground.svg");
  /* overflow: hidden; */
}
a{
  color:#c5e9e9bd;
}

.test-card {
  position: relative;
  min-height: 380px;
  font-size: 1.1rem;
  margin: auto; 
}
.test-title {
  min-height: 80px;
}

.custom-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
  left: 50px;
}

.icon {
  width: 130px;
  height: 80px;
  border-radius: 50%;
display: flex;
align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: 10px;
}