@import url(https://use.fontawesome.com/releases/v5.5.0/css/all.css);
@import url(https://use.fontawesome.com/releases/v5.5.0/css/all.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
}

html body {
  background-image: url(/static/media/subjectsbackground.404834ad.svg);
  /* overflow: hidden; */
}
a{
  color:#c5e9e9bd;
}

.test-card {
  position: relative;
  min-height: 380px;
  font-size: 1.1rem;
  margin: auto; 
}
.test-title {
  min-height: 80px;
}

.custom-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -25px;
  left: 50px;
}

.icon {
  width: 130px;
  height: 80px;
  border-radius: 50%;
display: flex;
align-items: center;
  justify-content: center;
  position: absolute;
  top: -5px;
  right: 10px;
}
.wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  background-image: url(/static/media/login.f728aae2.jpg);
  background-size: cover;
}
.login-box {
  
  width: 350px;
  height: 450px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #020005;
 
  border-radius: 12px;
  background-color: #ffffffc2;
  box-shadow: 2px 3px 9px #b977a2c4;
  font-family: 'Times New Roman', Times, serif;
}
.login-box h1 {
  font-size: 35px;
  margin-bottom: 25px;
  padding: 13px 0;
  color: #100d18;
  font-family: 'Courier New', Courier, monospace;

}
.head {
  margin-top: -35px;
}
.textbox {
  width: 100%;
  overflow: hidden;
  font-size: 20px;
  padding: 8px 0;
  margin: 8px 0;
}
.textbox i {
  margin-top: 10px;
  margin-left: 12px;
  width: 29px;
  float: left;
  text-align: center;
}
.textbox input {
  border: none;
  outline: none;
  background: transparent;
  color: #0a0909;
  font-size: 18px;
  font-weight: 400;
  width: 80%;
  float: left;
  margin: 0 10px;
  border-bottom: 1px solid #222;
}
.btns {
  width: 100%;
  /* background: none;  */
  /* border: 1px solid #ffffff9d; */
  border-radius:0px ;
  color: #ffffff;
  font-weight: 900;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  margin: 15px 2px;
}
.links{
  text-decoration: none;
  margin-bottom: 12px;

}
.errorMessage {
  font-size: 12px;
  letter-spacing: 0.05rem;
  color: #860a0a;
  padding-left: 0.25rem;
  font-style: italic;
}



a {
  transition: .3s all ease; }
  a:hover {
    text-decoration: none !important; }

.content {
  padding: 7rem 0; }

h2 {
  font-size: 20px; }

@media (max-width: 991.98px) {
  .content .bg {
    height: 500px; } }

.content .contents, .content .bg {
  width: 50%; }
  @media (max-width: 1199.98px) {
    .content .contents, .content .bg {
      width: 100%; } }
  .content .contents .form-group, .content .bg .form-group {
    position: relative; }
    .content .contents .form-group label, .content .bg .form-group label {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      transition: .3s all ease; }
    .content .contents .form-group input, .content .bg .form-group input {
      background: transparent;
      border-bottom: 1px solid #ccc; }
    .content .contents .form-group.first, .content .bg .form-group.first {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px; }
    .content .contents .form-group.last, .content .bg .form-group.last {
      border-bottom-left-radius: 7px;
      border-bottom-right-radius: 7px; }
    .content .contents .form-group label, .content .bg .form-group label {
      font-size: 12px;
      display: block;
      margin-bottom: 0;
      color: #b3b3b3; }
    .content .contents .form-group.focus, .content .bg .form-group.focus {
      background: #fff; }
    .content .contents .form-group.field--not-empty label, .content .bg .form-group.field--not-empty label {
      margin-top: -25px; }
  .content .contents .form-control, .content .bg .form-control {
    border: none;
    padding: 0;
    font-size: 20px;
    border-radius: 0; }
    .content .contents .form-control:active, .content .contents .form-control:focus, .content .bg .form-control:active, .content .bg .form-control:focus {
      outline: none;
      box-shadow: none; }

.content .bg {
  background-size: cover;
  background-position: center; }

.content a {
  color: #888;
  text-decoration: underline; }

.content .btn {
  height: 54px;
  padding-left: 30px;
  padding-right: 30px; }

.content .forgot-pass {
  position: relative;
  top: 2px;
  font-size: 14px; }

.social-login a {
  text-decoration: none;
  position: relative;
  text-align: center;
  color: #fff;
  margin-bottom: 10px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block; }
  .social-login a span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .social-login a:hover {
    color: #fff; }
  .social-login a.facebook {
    background: #3b5998; }
    .social-login a.facebook:hover {
      background: #344e86; }
  .social-login a.twitter {
    background: #1da1f2; }
    .social-login a.twitter:hover {
      background: #0d95e8; }
  .social-login a.google {
    background: #ea4335; }
    .social-login a.google:hover {
      background: #e82e1e; }

.control {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  cursor: pointer;
  font-size: 14px; }
  .control .caption {
    position: relative;
    top: .2rem;
    color: #888; }

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.control__indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: #e6e6e6;
  border-radius: 4px; }

.control--radio .control__indicator {
  border-radius: 50%; }

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc; }

.control input:checked ~ .control__indicator {
  background: #38d39f; }

.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #4dd8a9; }

.control input:disabled ~ .control__indicator {
  background: #e6e6e6;
  opacity: 0.9;
  pointer-events: none; }

.control__indicator:after {
  font-family: 'icomoon';
  content: '\e5ca';
  position: absolute;
  display: none;
  font-size: 16px;
  transition: .3s all ease; }

.control input:checked ~ .control__indicator:after {
  display: block;
  color: #fff; }

.control--checkbox .control__indicator:after {
  top: 50%;
  left: 50%;
  margin-top: -1px;
  transform: translate(-50%, -50%); }

.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #7b7b7b; }

.control--checkbox input:disabled:checked ~ .control__indicator {
  background-color: #7e0cf5;
  opacity: .2; }
  .btn-show-pass {
    font-size: 15px;
    color: #999999;
    display: flex;
    align-items: center;
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    padding-right: 5px;
    cursor: pointer;
    transition: all 0.4s;
  }
  
  .btn-show-pass:hover {
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(to left, #21d4fd, #b721ff);
  }
  
  .btn-show-pass.active {
    color: #6a7dfe;
    color: -webkit-linear-gradient(left, #21d4fd, #b721ff);
    color: -o-linear-gradient(left, #21d4fd, #b721ff);
    color: -moz-linear-gradient(left, #21d4fd, #b721ff);
    color: linear-gradient(to left, #21d4fd, #b721ff);
  }
  


.not-found {
  width: 560px;
  height: 225px;
  margin-right: -10px;
}
.starry-sky {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.search-icon {
  display: inline-block;
}
.notfound-copy {
  color: rgb(49, 12, 184);
  position: fixed;
  top: 25px;
  right: 10%;
  text-align: right;
}
h1 {
  font-weight: 700;
  font-size: 40px;
}
a {
  font-weight: 300;
  color: rgb(49, 12, 184);
  border-bottom: 1.5px solid rgb(49, 12, 184);
  text-decoration: none;
}
a:hover {
  font-weight: 300;
  color: rgb(49, 12, 184);
  border-bottom: 2px solid #000;
  text-decoration: none;
}
/* change to alternating star opacities */
.all-stars {
  animation: blinkblink 7s linear infinite;
}

@keyframes blinkblink {
  50% {
    opacity: 0.1;
  }
}

@media (max-width: 647px) {
  .moon {
    padding-top: -500px;
  }
}

.register-card{
  background-color: #fff;
  overflow: hidden;
}

.background{
  height: 100vh;
  display: flex;
  background-image: url(/static/media/register.e7e48bac.jpg);
  background-size: cover;
}
.Wrapper {
  width: 420px;
background-color:#fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #100d18;
  font-family: 'Times New Roman', Times, serif;
  
}
.btns {
  width: 100%;
  /* background: none; */
  border: 2px solid #e1ece2;
  color: #e9e5e5;
  font-weight: 900;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  margin: 15px 2px;
  border-radius:12px;
}
.Wrapper input {
  border: none;
  
  background: none;
  color: #0a0909;
  font-size: 18px;
  font-weight: 400;
  width: 80%;
  float: left;
  margin: 0 10px;
  border-bottom: 1px solid #222;
}
.registrationFormContainer {
  width: 94rem;
  border-radius: 12px;
  box-shadow: 2px 3px 9px #580139c4;
}
.box i {
  margin-top: 10px;
  margin-left: 12px;
  width: 29px;
  float: left;
  text-align: center;
}

.registrationFormLegend {
  width: 14rem;
    border-radius: 12px;
  background: #dee2e6;
}
.errorMessage {
  font-size: 10px;
  letter-spacing: 0.09rem;
  padding-left: 0.25rem;
}
.box{
  width: 100%;
  overflow: hidden;
  font-size: 20px;
  padding: 8px 0;
  margin: 8px 0;
}
.Rlinks{
  margin-left: 110px;
  -webkit-text-emphasis: none;
          text-emphasis: none;
}
@media screen and (max-width: 789px) {
  .Wrapper {
    margin-top: 10px;
  }
}
.header{
  border-bottom: 1px solid transparent;
  margin-top:-18px;
  border-radius: 12px;
  
}


.btn-primary{
  background-color: #6c63fe;
} 



.border-md {
    border-width: 2px;
}

.btn-facebook {
    background: #405D9D;
    border: none;
}

.btn-facebook:hover, .btn-facebook:focus {
    background: #314879;
}

.btn-twitter {
    background: #42AEEC;
    border: none;
}

.btn-twitter:hover, .btn-twitter:focus {
    background: #1799e4;
}



/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/

body {
    min-height: 100vh;
}

.form-control:not(select) {
    padding: 1.5rem 0.5rem;
}

select.form-control {
    height: 52px;
    padding-left: 0.5rem;
}

.form-control::-webkit-input-placeholder {
    color: #ccc;
    font-weight: bold;
    font-size: 0.9rem;
}

.form-control:-ms-input-placeholder {
    color: #ccc;
    font-weight: bold;
    font-size: 0.9rem;
}

.form-control::-ms-input-placeholder {
    color: #ccc;
    font-weight: bold;
    font-size: 0.9rem;
}

.form-control::placeholder {
    color: #ccc;
    font-weight: bold;
    font-size: 0.9rem;
}
.form-control:focus {
    box-shadow: none;
}
.home{
    height: 100vh;
    background-image: linear-gradient(
        rgba(133, 122, 122, 0.45), 
        rgba(97, 92, 92, 0.45)
      ),url(https://shivalikcollege.edu.in/assets/extra-images/images/ShivalikCollege_Dehradun.webp) ;
      background-size: cover;
  }
.nav__bar{
    color: #222;
    background: #f7f8f800;
    height: 5rem;
    padding: 1rem;
}

.containers{
    margin-top: 13rem;
    font-weight: 900;
}
.containers .h2{
    text-shadow: 2px 2px #020202;
    color: #f7f8f8;
    font-weight: 600;
}

.nav__btn{
    display: flex;
}
.nav__btn__link{
    border-radius: 0;
    color: #505152;
    white-space: nowrap;
    padding: 12px 26px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}
.btn1{
    background: #DCB58C;
    color: #f7f8f8;

}
.btn2{
    background: #538cb4;
    color: #f7f8f8;
}
.btn3{
    color: #f7f8f8;
    background: #1181B2;
    padding: 12px 50px;
    font-size: 19px;
}

.nav__btn__link:hover {
    transition: all 0.2s ease-in-out;
    background: #DDEDF4;
    font-weight: bold;
    color: #212221;
}
@media  screen and (max-width: 798px){
    .containers{
        margin-top: 10rem;
        
    }
    .containers .h2{
        text-shadow: 1px 2px #020202;
        font-size: 36px;
        font-weight: 600;
    }
    
} 
