@import "https://use.fontawesome.com/releases/v5.5.0/css/all.css";
.register-card{
  background-color: #fff;
  overflow: hidden;
}

.background{
  height: 100vh;
  display: flex;
  background-image: url(../images/register.jpg);
  background-size: cover;
}
.Wrapper {
  width: 420px;
background-color:#fff;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #100d18;
  font-family: 'Times New Roman', Times, serif;
  
}
.btns {
  width: 100%;
  /* background: none; */
  border: 2px solid #e1ece2;
  color: #e9e5e5;
  font-weight: 900;
  padding: 5px;
  font-size: 20px;
  cursor: pointer;
  margin: 15px 2px;
  border-radius:12px;
}
.Wrapper input {
  border: none;
  
  background: none;
  color: #0a0909;
  font-size: 18px;
  font-weight: 400;
  width: 80%;
  float: left;
  margin: 0 10px;
  border-bottom: 1px solid #222;
}
.registrationFormContainer {
  width: 94rem;
  border-radius: 12px;
  box-shadow: 2px 3px 9px #580139c4;
}
.box i {
  margin-top: 10px;
  margin-left: 12px;
  width: 29px;
  float: left;
  text-align: center;
}

.registrationFormLegend {
  width: 14rem;
    border-radius: 12px;
  background: #dee2e6;
}
.errorMessage {
  font-size: 10px;
  letter-spacing: 0.09rem;
  padding-left: 0.25rem;
}
.box{
  width: 100%;
  overflow: hidden;
  font-size: 20px;
  padding: 8px 0;
  margin: 8px 0;
}
.Rlinks{
  margin-left: 110px;
  text-emphasis: none;
}
@media screen and (max-width: 789px) {
  .Wrapper {
    margin-top: 10px;
  }
}
.header{
  border-bottom: 1px solid transparent;
  margin-top:-18px;
  border-radius: 12px;
  
}


.btn-primary{
  background-color: #6c63fe;
} 



.border-md {
    border-width: 2px;
}

.btn-facebook {
    background: #405D9D;
    border: none;
}

.btn-facebook:hover, .btn-facebook:focus {
    background: #314879;
}

.btn-twitter {
    background: #42AEEC;
    border: none;
}

.btn-twitter:hover, .btn-twitter:focus {
    background: #1799e4;
}



/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/

body {
    min-height: 100vh;
}

.form-control:not(select) {
    padding: 1.5rem 0.5rem;
}

select.form-control {
    height: 52px;
    padding-left: 0.5rem;
}

.form-control::placeholder {
    color: #ccc;
    font-weight: bold;
    font-size: 0.9rem;
}
.form-control:focus {
    box-shadow: none;
}