.home{
    height: 100vh;
    background-image: linear-gradient(
        rgba(133, 122, 122, 0.45), 
        rgba(97, 92, 92, 0.45)
      ),url(https://shivalikcollege.edu.in/assets/extra-images/images/ShivalikCollege_Dehradun.webp) ;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
  }
.nav__bar{
    color: #222;
    background: #f7f8f800;
    height: 5rem;
    padding: 1rem;
}

.containers{
    margin-top: 13rem;
    font-weight: 900;
}
.containers .h2{
    text-shadow: 2px 2px #020202;
    color: #f7f8f8;
    font-weight: 600;
}

.nav__btn{
    display: flex;
}
.nav__btn__link{
    border-radius: 0;
    color: #505152;
    white-space: nowrap;
    padding: 12px 26px;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 1rem;
    text-decoration: none;
    transition: all 0.2s ease-in-out;
}
.btn1{
    background: #DCB58C;
    color: #f7f8f8;

}
.btn2{
    background: #538cb4;
    color: #f7f8f8;
}
.btn3{
    color: #f7f8f8;
    background: #1181B2;
    padding: 12px 50px;
    font-size: 19px;
}

.nav__btn__link:hover {
    transition: all 0.2s ease-in-out;
    background: #DDEDF4;
    font-weight: bold;
    color: #212221;
}
@media  screen and (max-width: 798px){
    .containers{
        margin-top: 10rem;
        
    }
    .containers .h2{
        text-shadow: 1px 2px #020202;
        font-size: 36px;
        font-weight: 600;
    }
    
} 